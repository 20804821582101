//----------------------------------------------------------//
//	SLIDER
//----------------------------------------------------------//
.hero-slider-wrapper {
  position: relative;
  min-height: $hero-slider-height;
  .hero-slider {
    .owl-slide {
      position: relative;
      height: $hero-slider-height;
      background-size: cover;
      background-position: center center;
      &.bg-overlay:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: rgba($gray-900, 0.5);
      }
      &.bg-overlay-300:before {
        background: rgba($gray-900, 0.3);
      }
      &.bg-overlay-400:before {
        background: rgba($gray-900, 0.4);
      }
      &.bg-overlay [class*="container"] > * {
        position: relative;
        z-index: 3;
      }
    }
  }
}
@media (max-width: 767px) {
  .hero-slider-wrapper {
    min-height: $hero-slider-height-mobile !important;
  }
  .hero-slider {
    .owl-slide {
      height: $hero-slider-height-mobile !important;
    }
    .owl-nav {
      display: none;
    }
  }
}