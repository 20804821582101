//----------------------------------------------------------//
//	DROPDOWN
//----------------------------------------------------------//
.dropdown-menu {
  border: 0;
}
.dropdown-item {
  font-weight: $nav-link-font-weight;
  letter-spacing: $letter-spacing;
}
.dropdown-item {
  &:hover,
  &:focus {
    outline: 0;
  }
}
.dropdown-header {
  padding-top: 0;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-lg;
}
.center-nav.fixed .navbar-nav>.nav-item.language-select>.dropdown-menu {
  margin-top: $dropdown-spacer !important;
}
.center-nav[class*="navbar-bg-"] .navbar-nav>.nav-item.language-select>.dropdown-menu {
  margin-top: 1.25rem !important;
}
.navbar.transparent .navbar-nav>.nav-item.language-select>.dropdown-menu {
  margin-top: 1.2rem !important;
}
.navbar.transparent.fixed .navbar-nav>.nav-item.language-select>.dropdown-menu,
.navbar[class*="navbar-bg-"] .navbar-nav>.nav-item.language-select>.dropdown-menu {
  margin-top: 2rem !important;
}
@media (min-width: 992px) {
  .navbar:not(.transparent) .navbar-nav>.nav-item:not(.language-select)>.dropdown-menu,
  .navbar.fixed .navbar-nav>.nav-item>.dropdown-menu {
    margin-top: $dropdown-spacer !important;
  }
  .navbar.extended:not(.fixed) .navbar-nav>.nav-item.language-select>.dropdown-menu {
    margin-top: 0.95rem !important;
  }
  .navbar[class*="navbar-bg-"]:not(.fancy):not(.extended):not(.fixed) .navbar-nav>.nav-item.language-select>.dropdown-menu {
    margin-top: 2.25rem !important;
  }
  .navbar.fixed .navbar-nav>.nav-item.language-select>.dropdown-menu {
    margin-top: 1.95rem !important;
  }
}
@media (max-width: 991.98px) {
  .navbar .navbar-collapse .dropdown-menu {
    padding: 0;
    margin-top: 0;
    background: none;
  }
  .navbar .navbar-collapse .dropdown-menu .dropdown-item {
    color: $navbar-dark-color;
    padding-left: 1rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  .dropdown-header {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 0.5rem;
  }
}
//----------------------------------------------------------//
//	LANGUAGE DROPDOWN
//----------------------------------------------------------//
.language-select .dropdown-menu {
  left: 50% !important;
  transform: translateX(-50%) !important;
  top: 100% !important;
  margin-top: 0;
  padding: $dropdown-padding-y-sm $dropdown-padding-x;
  position: absolute;
}
//----------------------------------------------------------//
//	SMARTMENUS
//----------------------------------------------------------//
.navbar-nav .sub-arrow {
  font-family: $font-family-custom-icons;
}
.navbar-nav.sm-collapsible .sub-arrow {
  top: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  padding: 0 2.2rem 0 .5rem;
  @include font-size($caret-size - 0.05);
  line-height: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.navbar-nav.sm-collapsible .sub-arrow::before,
.navbar-nav.sm-collapsible .show>a>.sub-arrow::before {
  content: $icon-caret-down;
  display: table-cell;
  vertical-align: middle;
}
.navbar-nav.sm-collapsible .dropdown-menu .dropdown-menu {
  margin: 0 0 0 1rem;
}
.navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow {
  display: inline-block;
  width: auto;
  height: auto;
  margin-left: 0.05rem;
  margin-right: -0.25rem;
  margin-bottom: -0.15rem;
  vertical-align: -2px;
  border: 0 !important;
  @include font-size($caret-size - 0.05);
  color: $main-color;
}
.navbar.caret-none .navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow {
  display: none;
}
.navbar-dark .navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow {
  color: $navbar-dark-color;
}
.navbar-dark.transparent.banner--stick .navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow {
  color: $main-color;
}
.navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow+.sub-arrow {
  display: none !important;
}
.navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow:before {
  position: relative;
  content: $icon-caret-down;
}
.navbar-nav:not(.sm-collapsible) .dropdown-item .sub-arrow,
.navbar-nav:not(.sm-collapsible):not([data-sm-skip]) .dropdown-menu .dropdown-toggle::after {
  @include font-size($caret-size);
  top: 0.2rem;
  right: 1.8rem;
  margin: 0;
  border: 0;
}
.navbar-nav:not(.sm-collapsible) .dropdown-item .sub-arrow:before,
.navbar-nav:not(.sm-collapsible):not([data-sm-skip]) .dropdown-menu .dropdown-toggle::after {
  position: relative;
  content: $icon-caret-right;
}
.navbar-nav:not(.sm-collapsible) .dropdown-item.has-submenu {
  padding-right: 2.5rem;
}
.navbar-nav .scroll-up-arrow,
.navbar-nav .scroll-down-arrow {
  font-family: $font-family-custom-icons;
  border:0;
  display: block;
  width: auto;
  height: auto;
  color: $navbar-dark-color;
  top: 50%;
  transform: translateY(-50%);
  &:before {
    display: block;
    @include font-size($caret-size + 0.1);
  }
}
.navbar-nav .scroll-up-arrow {
  &:before {
    content: $icon-caret-up;
  }
}
.navbar-nav .scroll-down-arrow {
  &:before {
    content: $icon-caret-down;
  }
}
//----------------------------------------------------------//
//	DROPDOWN TOGGLE
//----------------------------------------------------------//
.dropdown-toggle::after {
  font-family: $font-family-custom-icons;
  border: 0;
  @include font-size($caret-size);
  margin-left: 0;
  margin-right: -0.25rem;
  vertical-align: -1px;
  content: $icon-caret-down;
  color: $main-color;
}
@media (max-width: 991.98px) {
  .dropdown-toggle::after {
    vertical-align: 0;
  }
}
.navbar-dark:not(.fixed) .dropdown-toggle:after,
.navbar-bg-dark .dropdown-toggle:after,
.btn.dropdown-toggle:after {
  color: $white;
}
//----------------------------------------------------------//
//	SHARE DROPDOWN
//----------------------------------------------------------//
.share-dropdown .dropdown-menu {
  min-width: 6.25rem;
  margin-top: 1rem !important;
  padding-top: 0.65rem !important;
  padding-bottom: 0.65rem !important;
  &[data-popper-placement^='top'] {
    margin-top: 0 !important;
    margin-bottom: 1rem !important;
  }
  .dropdown-item {
    padding: 0.25rem 1.15rem;
    @include font-size(0.7rem);
    i {
      padding-right: 0.4rem;
      vertical-align: -0.1rem;
      width: 1rem;
      @include font-size(0.8rem);
    }
  }
}
.share-dropdown .dropdown-toggle::after {
  display: none;
}
//----------------------------------------------------------//
//	MEGA MENU
//----------------------------------------------------------//
.navbar-nav,
.navbar-nav>li.mega-menu {
  position: static !important;
}
.navbar-nav>li>ul.mega-menu {
  right: 0 !important;
  width: auto !important;
  max-width: none !important;
}
@media (min-width: 992px) {
  .navbar .navbar-nav ul.show-animation {
    animation: show-animation 0.25s;
  }
  .navbar .navbar-nav ul.hide-animation {
    animation: hide-animation 0.25s;
  }
  .navbar-nav>li>ul.mega-menu,
  .navbar-nav .scroll-down, .navbar-nav .scroll-up {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mega-menu-content>.row>[class*="col-"]+[class*="col-"] {
    border-left: 1px solid $border-light;
  }
  .navbar-nav>li>ul.mega-menu .mega-menu-content {
    padding: 0.6rem 0.25rem 0.2rem;
  }
  .mega-menu-dark {
    background: $navy;
    .img-svg {
      background: rgba($white, 0.05);
      transition: $btn-transition;
      &:hover {
        background: rgba($white, 0.09);
      }
    }
    .dropdown-item {
      color: $navbar-dark-color;
      &:hover,
      &:focus {
        color: $navbar-dark-color;
      }
    }
  }
  .mega-menu-img {
    .mega-menu-content {
      padding: 1rem 2rem !important
    }
    .dropdown-item {
      padding-left: 0;
      padding-right: 0;
    }
  }
}