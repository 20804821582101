//----------------------------------------------------------//
//	FORMS
//----------------------------------------------------------//
input,
textarea,
select {
  -webkit-appearance: none !important;
}
.form-floating {
  > label {
    color: $form-label-color;
    @include font-size($input-font-size);
  }
  &.input-group label {
    margin-left: 0.05rem !important;
  }
  > textarea.form-control {
    &:focus,
    &:not(:placeholder-shown) {
      padding-top: $form-floating-input-padding-t + 0.3rem;
    }
    &:-webkit-autofill {
      padding-top: $form-floating-input-padding-t + 0.3rem;
    }
  }
}
.search-form {
  position: relative;
  .form-control {
    margin: 0;
    padding-right: 2.25rem !important;
  }
  &:before {
    content: $icon-search;
    font-family: $font-family-unicons;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.75rem;
    @include font-size(0.9rem);
    color: $input-placeholder-color;
    z-index: 1;
  }
}
.form-check-input {
  box-shadow: $box-shadow;
  margin-top: 0.1rem;
}
.form-select {
  background-clip: padding-box;
}
.input-group>.form-control:focus, .input-group>.form-select:focus {
  z-index: 0;
}
.input-group>.btn {
  max-height: 2.6rem;
}
form.dark-fields .form-control {
  background: rgba($white, 0.03);
  border-color: rgba($white, 0.1);
  color: $gray-300;
  &:focus {
    border-color: rgba($main-color, 0.5);
  }
}