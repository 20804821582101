// Theme Colors
$sky: #5eb9f0;
$blue: #095379;
$grape: #605dba;
$purple: #747ed1;
$violet: #a07cc5;
$pink: #d16b86;
$fuchsia: #e668b3;
$red: #ad1010;
$orange: #C84B31;
$yellow: #FBAB1E;
$green: #45c4a0;
$leaf: #7cb798;
$aqua: #54a8c7;
$navy: #2D4263;
$ash: #9499a3;