// Use this to write your custom SCSS

$primary: #c99c3c;

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
   color: #c59734;
}

.text-split--2 {
   white-space: pre-wrap;
   word-break: break-word;
   text-overflow: ellipsis;
   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
}

.navbar {
   &.position-absolute:not(.fixed) {
      button.hamburger {
         &:before,
         &:after,
         span {
            background: #c59734;
         }
      }
   }
}

.navbar-light {
   .navbar-nav {
      .nav-link {
         color: #c99c3c;
      }
   }
}

.owl-carousel {
   .owl-stage-outer {
      border-radius: 0.4rem !important;
   }
}

a.button-float__whatsapp,
a.button-float__phone {
   position: fixed;
   width: 60px;
   height: 60px;
   bottom: 40px;
   right: 20px;
   background-color: #128C7E;
   color: #FFF;
   border-radius: 50px;
   text-align: center;
   z-index: 100;
   text-decoration: none;
}

a.button-float__whatsapp .button-float__icon,
a.button-float__phone .button-float__icon {
   font-size: 35px;
}

.breadcrumb-item+.breadcrumb-item::before {
   font-size: 1rem;
   line-height: 1;
   padding-bottom: 0;
}

@media (max-width: 991.98px) {
   .navbar .navbar-brand {
      padding-top: .8rem;
      padding-bottom: .8rem;
   }
}

@media (min-width: 992px) {
   .navbar.fancy:not(.fixed) {
      .navbar-collapse-wrapper {
         padding: 0 1.5rem 0 1.5rem;
         margin-top: 1rem;
      }

      .navbar-collapse {
         .nav-link {
            padding-top: 1rem;
            padding-bottom: 1rem;
         }
      }
   }

   .navbar.transparent:not(.fixed) {
      padding-top: 0;
   }
}


.underline-3:after {
   bottom: -.5rem;
}

.owl-carousel {
   .owl-nav button {
      &.owl-next,
      &.owl-prev {
         @include media-breakpoint-down(lg) {
            display: none;
         }
      }
   }
}